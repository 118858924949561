import React, { useEffect, FC } from "react";
import { navigate } from "gatsby";
import { SEO } from "../../../components/seo";
import Layout from "../../../components/Layout/Layout";
import WatchNowMain from "../../../components/WatchNowUiComponents/WatchNowMain";
import SeoImg from "../../../images/re-wind-watchnow-seo.jpg";
import { Buffer } from "buffer";

const ProtectedSubpage = () => {
  useEffect(() => {
    // Function to validate token from query parameters
    const validateToken = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
      if (
        (!token && !base64Regex.test(token)) ||
        Number.isNaN(
          new Date(Buffer.from(token, "base64").toString()).getTime()
        )
      ) {
        navigate(".."); // Redirect to unauthorized page if token is missing
      } else {
        const currentDate = new Date().getTime(); // Convert to number
        const decodedDate = new Date(
          Buffer.from(token, "base64").toString()
        ).getTime();
        // Convert to number
        const diffInMs = currentDate - decodedDate;
        // Convert milliseconds to days
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        if (diffInDays > 7) {
          navigate(".."); // Redirect to unauthorized page if token is invalid
        }
      }
    };
    validateToken();
  }, []);

  return (
    <Layout location="/rewind">
      <SEO
        title="re:Wind 2024 Watch Now"
        description="The Serverless World Podcast - Listen to experts talking about modern challenges and opportunities within the cloud and its various potentials for your business."
        type="website"
        image={SeoImg}
      />
      <WatchNowMain />
    </Layout>
  );
};

export default ProtectedSubpage;
