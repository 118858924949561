import React, { FC, useState } from "react";
import * as styles from "./TwoColumVideo.module.scss";
import InnerWrapper from "../InnerWrapper/InnerWrapper";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../CircleOutlineAndBlur/CircleBlur";

import minusSolid from "../../../images/minus-solid.svg";
import plusSolid from "../../../images/plus-solid.svg";

import "react-h5-audio-player/lib/styles.css";
import cx from "classnames";
import TwoColumnVideoListItem from "./TwoColumVideoListItem";
import { UseWindowSize } from "../../hooks/UseWindowSize";
type Props = {
  isVideoShow?: boolean;
  ourStory?: boolean;
  ourStorySecond?: boolean;
  style1?: string;
  style2?: string;
  order1?: number;
  order2?: number;
  episodes: any[];
};

const TwoColumVideo: FC<Props> = ({
  isVideoShow = true,
  ourStory,
  ourStorySecond,
  style1,
  style2,
  order1 = 1,
  order2 = 2,
  episodes,
}) => {
  const [activeIndexes, setActiveIndexes] = useState(new Set());
  const [activeItem, setActiveItem] = useState(episodes[0].id);
  const [activeAudio, setActiveAudio] = useState(episodes[0].media_url);
  const { width } = UseWindowSize();

  return (
    <InnerWrapper>
      <div className={styles.development} style={{ zIndex: 2 }}>
        <div
          className={
            ourStory || ourStorySecond ? styles.knowUsOurStory : styles.knowUs
          }
        >
          <div
            className={
              ourStory
                ? styles.ourStoryImg
                : ourStorySecond
                ? ""
                : cx(styles.knowUsImage, style1)
            }
            style={{ order: order2 }}
          >
            <div
              className={
                ourStory || ourStorySecond
                  ? styles.ourStoryImgDiv
                  : styles.knowUsImageDiv
              }
              style={{
                position: "relative",
                display: isVideoShow ? "block" : "none",
              }}
            >
              <div className={styles.videoWrapper}>
                <div
                  style={{
                    width: "100%",
                    height: "80px",
                    position: "absolute",
                    left: "0",
                    top: "0",
                    cursor: "none",
                    backgroundColor: "transparent",
                    zIndex: 9999999999,
                  }}
                ></div>
                <iframe
                  className={styles.testimonialVideo}
                  src={activeAudio}
                  title="YouTube video player"
                  id="ytplayer"
                  frameBorder="0"
                  allow="encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div
            className={
              ourStory
                ? styles.knowUsOurStoryText
                : ourStorySecond
                ? ""
                : cx(styles.knowUsText, style2)
            }
            style={{
              order: order1,
              width: width <= 770 ? "90%" : "45%",
              margin: width <= 770 ? "0 auto" : "",
            }}
          >
            <div
              style={{
                minHeight: "300px",
                height: "100%",
                width: "90%",
                margin: "0 auto",
                marginBottom: "25px",
                zIndex: 9999999999,
              }}
            >
              <h2 className={ourStory ? styles.ourStoryText : ""}>.Sessions</h2>

              <ul style={{ color: "white" }}>
                {episodes.map(item => {
                  return (
                    <li
                      key={item.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: activeItem === item.id ? "#00ecbd" : "white",
                        fontFamily: "Poppins",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setActiveItem(item.id);
                            setActiveAudio(item.media_url);
                          }}
                        >
                          {item.title}
                        </span>

                        <img
                          style={{
                            height: "15px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          src={
                            !activeIndexes.has(item.id) ? plusSolid : minusSolid
                          }
                          alt="expand for description of talk"
                          onClick={() => {
                            const newIndices = new Set(activeIndexes);
                            if (activeIndexes.has(item.id)) {
                              newIndices.delete(item.id);
                            } else {
                              newIndices.add(item.id);
                            }
                            setActiveIndexes(newIndices);
                          }}
                        />
                      </div>
                      {activeIndexes.has(item.id) ? (
                        <TwoColumnVideoListItem
                          description={item.content}
                          host={item.host}
                          guestOne={item.guestOne}
                          guestTwo={item.guestTwo}
                          guestThree={item.guestThree}
                          guestFour={item.guestFour}
                        />
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </div>

            {ourStory && !ourStorySecond ? (
              <>
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />
                <CircleOutline
                  isBlurVisible={true}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundCircleLeftStory}
                />
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeft}
                />
              </>
            ) : ourStorySecond ? (
              <>
                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.8}
                  animateOpacity={0.2}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeftSecond}
                />
                <CircleOutline
                  isBlurVisible={true}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundCircleLeftStorySecond}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </InnerWrapper>
  );
};

export default TwoColumVideo;
