import React from "react";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";

import rewindWatchNow from "../../images/rewind-watch-now-header.webp";
import { UseWindowSize } from "../hooks/UseWindowSize";
import translate from "../../i18n/translate";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import TwoColumVideo from "../Shared/TwoColumVideo/TwoColumVideo";
import { videos } from "./talk.data";

const WatchNowMain = () => {
  const width = UseWindowSize();

  return (
    <div>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={"re:Wind 2024 Watch Now"}
          description={translate("podcast-description")}
          homeBackground={rewindWatchNow}
          date=""
          alt="Contact us"
        />
      ) : (
        <HeroBanner
          heading={"re:Wind 2024 Watch Now"}
          description={translate("podcast-description")}
          homeBackground={rewindWatchNow}
        />
      )}

      <InnerWrapper>
        <TwoColumVideo order2={1} order1={2} episodes={videos} />
      </InnerWrapper>
    </div>
  );
};

export default WatchNowMain;
