// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumVideo-module--aboutMainCircleBlurBlue---bXiE";
export var bHeading = "TwoColumVideo-module--bHeading--Oj251";
export var development = "TwoColumVideo-module--development--fZe7c";
export var gdnPromotePic = "TwoColumVideo-module--gdnPromotePic--j79rl";
export var head = "TwoColumVideo-module--head--BlJm6";
export var heroBackgroundCircleLeft = "TwoColumVideo-module--heroBackgroundCircleLeft--bRRsR";
export var heroBackgroundCircleLeftStory = "TwoColumVideo-module--heroBackgroundCircleLeftStory--cdqcT";
export var heroBackgroundCircleLeftStorySecond = "TwoColumVideo-module--heroBackgroundCircleLeftStorySecond--J8gAP";
export var heroBackgroundInnerCircleLeft = "TwoColumVideo-module--heroBackgroundInnerCircleLeft--Ltleo";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumVideo-module--heroBackgroundInnerCircleLeftSecond--f0Qel";
export var knowUs = "TwoColumVideo-module--knowUs--CP4Sw";
export var knowUsHeading = "TwoColumVideo-module--knowUsHeading--NKcdT";
export var knowUsImage = "TwoColumVideo-module--knowUsImage--pdj-u";
export var knowUsImageDiv = "TwoColumVideo-module--knowUsImageDiv--gViWT";
export var knowUsOurStory = "TwoColumVideo-module--knowUsOurStory--Mj3Jy";
export var knowUsOurStoryText = "TwoColumVideo-module--knowUsOurStoryText--q0G4Y";
export var knowUsText = "TwoColumVideo-module--knowUsText--wM9VS";
export var ourStoryDescPara = "TwoColumVideo-module--ourStoryDescPara--9C7aw";
export var ourStoryImg = "TwoColumVideo-module--ourStoryImg--5dTt7";
export var ourStoryImgDiv = "TwoColumVideo-module--ourStoryImgDiv--FjYeF";
export var ourStoryText = "TwoColumVideo-module--ourStoryText--wSJtv";
export var storyPromotePic = "TwoColumVideo-module--storyPromotePic--CQ8PA";
export var storyPromotePicSecond = "TwoColumVideo-module--storyPromotePicSecond--MrtXW";
export var testimonialVideo = "TwoColumVideo-module--testimonialVideo--51rF-";
export var topHeading = "TwoColumVideo-module--topHeading--9S8Cf";
export var videoWrapper = "TwoColumVideo-module--videoWrapper--2Zr9p";